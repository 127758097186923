<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-row>
            <v-col>
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span
                >Warehouse</label
              >
              <v-select
                v-model="company_warehouse"
                :items="company_warehouses"
                item-text="name"
                item-value="id"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                  mt-5
                "
                @change="searchData"
                placeholder="Select a GRN No:"
                :rules="[(v) => !!v || ' GRN No: is Required']"
                outlined
                single-line
                height="48"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>From (Start
                date)</label
              >

              <v-menu
                ref="start_date_menu"
                v-model="start_date_menu"
                :close-on-content-click="false"
                :return-value.sync="start_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="Start Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="start_date"
                  :max="end_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="start_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_date_menu.save(start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>To (End
                date)</label
              >

              <v-menu
                ref="end_date_menu"
                v-model="end_date_menu"
                :close-on-content-click="false"
                :return-value.sync="end_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="To Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="end_date"
                  :min="start_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="end_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_date_menu.save(end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mt-11
                "
                @click="search"
                >Search</v-btn
              >
            </v-col>
          </v-row>

          <v-form ref="frmFilter">
            <v-row class="mt-8">
              <v-col cols="12" md="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Delivery Code:</label
                >
                <v-select
                  v-model="dispatchMaster_id"
                  :items="grn"
                  item-text="dispatch_code"
                  item-value="id"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  @change="searchData"
                  placeholder="Select a GRN No:"
                  :rules="[(v) => !!v || ' GRN No: is Required']"
                  outlined
                  single-line
                  height="48"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3" align-self="center"> </v-col>
              <v-col cols="12" offset-md="3" md="3"> </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card class="card-shadow border-radius-xl mt-6" v-show="show_table">
          <v-card-text class="px-0 py-0">
            <v-form ref="frmTable">
              <v-data-table
                v-model="tastingData"
                :headers="headers"
                :items="tableData"
                item-key="id"
                fixed-header
                show-select
                class="table"
                :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }"
                @keyup="changeFocusInput"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title></v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      @click="submit"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mb-5
                        mt-5
                      "
                      >Submit
                    </v-btn>
                    <br />
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      dark
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-primary
                        bg-success
                        py-5
                        px-5
                        ma-5
                        shadow
                      "
                      @click="importExcel"
                    >
                      <v-icon size="20" class="text-white mr-1">
                        fas fa-solid fa-file-export </v-icon
                      >Export as Excel</v-btn
                    >
                  </v-toolbar>
                </template>
                <template v-slot:[`item.item_name`]="{ item }">
                  <!-- <span> Example test {{ item.district }}</span> -->
                  <!--    :disabled="checkData(item)" -->
                  <v-text-field
                    disabled
                    v-model="item.item_name"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                      mb-4
                      centered-input
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    persistent-hint
                  ></v-text-field>
                </template>
                <!--  -->
                <template v-slot:[`item.leaf_appearance`]="{ item, index }">
                  <v-select
                    v-model.number="item.leaf_appearance"
                    :items="leaf_appearance"
                    item-text="value"
                    item-value="value"
                    @change="getItemName(item)"
                    @keyup="changeFocusInput"
                    :ref="`input-${index * 6 + 1}`"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    single-line
                    height="48"
                  >
                  </v-select>
                </template>

                <template v-slot:[`item.leaf_size`]="{ item, index }">
                  <v-select
                    v-model.number="item.leaf_size"
                    :items="leaf_size"
                    item-text="value"
                    item-value="value"
                    @change="getItemName(item)"
                    @keyup="changeFocusInput"
                    :ref="`input-${index * 6 + 2}`"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    single-line
                    height="48"
                  >
                  </v-select>
                </template>

                <template v-slot:[`item.color`]="{ item, index }">
                  <v-select
                    v-model.number="item.color"
                    :items="color"
                    item-text="value"
                    item-value="value"
                    @change="getItemName(item)"
                    @keyup="changeFocusInput"
                    :ref="`input-${index * 6 + 3}`"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    single-line
                    height="48"
                  >
                  </v-select>
                </template>
                <template v-slot:[`item.body`]="{ item, index }">
                  <v-select
                    v-model.number="item.body"
                    :items="body"
                    item-text="value"
                    item-value="value"
                    @change="getItemName(item)"
                    @keyup="changeFocusInput"
                    :ref="`input-${index * 6 + 4}`"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    single-line
                    height="48"
                  >
                  </v-select>
                </template>
                <template v-slot:[`item.taste`]="{ item, index }">
                  <v-select
                    v-model.number="item.taste"
                    :items="taste"
                    item-text="value"
                    item-value="value"
                    @change="getItemName(item)"
                    @keyup="changeFocusInput"
                    :ref="`input-${index * 6 + 5}`"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    single-line
                    height="48"
                  >
                  </v-select>
                </template>
                <template v-slot:[`item.special_marking`]="{ item }">
                  <v-text-field
                    tabindex="-1"
                    v-model="item.special_marking"
                    @input="getItemName(item)"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                      mb-4
                      centered-input
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    :value="item.special_marking"
                    persistent-hint
                    @keyup="changeFocusInput"
                    style="width: 100px"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.comment`]="{ item, index }">
                  <v-text-field
                    v-model="item.comment"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                      mb-4
                      centered-input
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    @keyup="changeFocusInput"
                    :ref="`input-${index * 6 + 6}`"
                    :value="item.comment"
                    persistent-hint
                  ></v-text-field>
                </template>
              </v-data-table> </v-form
          ></v-card-text>

          <v-card-actions>
            <v-row>
              <v-col offset-md="5"
                ><v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  @click="submit"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mb-5
                  "
                  >Submit
                </v-btn></v-col
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import blend from "../../../../api/blend-api";
import { LABEL_TASTING_FEATURE } from "../../../../global-data/labels";
import company_warehouse from "../../../master/CompanyWareHouse/api";
export default {
  data() {
    return {
      start_date_menu: false,
      end_date_menu: false,
      start_date: null,
      end_date: null,
      currentItem: 1,
      grn: [],
      dispatchMaster_id: null,
      overlay: false,
      tastingData: [],
      tableData: [],
      show_table: false,
      company_warehouse: [],
      company_warehouses: null,
      // ----
      leaf_appearance: [],
      leaf_size: [],
      color: [],
      body: [],
      taste: [],
      //
      headers: [
        {
          text: "Item code",
          value: "item_code",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Garden",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: true,
        },

        {
          text: "Appearance",
          value: "leaf_appearance",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Size",
          value: "leaf_size",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Color",
          value: "color",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Body",
          value: "body",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Taste",
          value: "taste",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },

        {
          text: "Comment",
          value: "comment",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Generated item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: LABEL_TASTING_FEATURE,
          value: "special_marking",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
      ],
    };
  },
  watch: {
    currentItem: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.focusInput(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.start_date = this.formatDate(d);
      this.end_date = this.formatDate(d1);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    async initialize() {
      this.overlay = true;
      // ## Geting Blend factors
      let result = await blend.getBlendfactor();
      this.body = result.body;
      this.leaf_size = result.leaf_size;
      this.color = result.color;
      this.taste = result.taste;
      this.leaf_appearance = result.leaf_appearance;
      this.company_warehouses = await company_warehouse.get();
      this.company_warehouse = this.company_warehouses[0].id;
      await this.setDates();
      this.grn = await api.getDeliveryCode(this.start_date, this.end_date);
      if (this.grn.length == 0) {
        this.noDataAlert("No Data Found");
      }
      this.show_table = false;
      this.overlay = false;
    },

    getIndex(item) {
      return this.myAllocation.indexOf(item);
    },

    getInput(id) {
      const input = this.$refs[`input-${id}`];
      return input;
    },
    changeFocusInput(event) {
      // alert("key up");
      const key_code = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
      };

      let nextItem = this.currentItem;
      if (event.keyCode == key_code.left) {
        nextItem -= 1;
      } else if (event.keyCode == key_code.right) {
        nextItem += 1;
      }

      if (this.getInput(nextItem)) {
        this.currentItem = nextItem;
      }
    },
    focusInput(id) {
      const input = this.getInput(id);
      if (input) {
        input.focus();
      }
    },

    validate() {
      return this.$refs.frmFilter.validate();
    },

    async search() {
      try {
        this.overlay = true;
        this.tableData = [];
        this.tastingData = [];
        this.grn = [];
        this.dispatchMaster_id = null;
        this.show_table = false;

        this.grn = await api.getDeliveryCode(
          this.start_date,
          this.end_date,
          this.company_warehouse
        );
        if (this.grn.length > 0) {
        } else {
          this.noDataAlert("No Data Found...");
        }
        this.overlay = false;
      } catch (error) {
        console.log(error);
      }
    },
    async searchData() {
      // console.log(this.validate());
      if (this.validate()) {
        try {
          this.show_table = false;
          this.overlay = true;
          this.tableData = [];
          this.tastingData = [];
          this.tableData = await api.getGrnSearchDetails(
            this.dispatchMaster_id
          );
          if (this.tableData.length > 0) {
            this.show_table = true;
          } else {
            this.noDataAlert("No Data Found...");
          }
          this.overlay = false;
        } catch (error) {}
      }
    },

    async importExcel() {
      if (this.validate()) {
        try {
          this.overlay = true;
          await api.importExcel(this.dispatchMaster_id);
          this.overlay = false;
        } catch (error) {}
      }
    },

    async submit() {
      if (this.tastingData.length > 0) {
        let req = [];
        this.tastingData.forEach((el) => {
          if (el.item_name != null) {
            let d = {
              dispatchchild: el.id,
              batch_no: el.batch_no,
              item_name: el.item_name,
              leaf_appearance: el.leaf_appearance,
              leaf_size: el.leaf_size,
              color: el.color,
              body: el.body,
              taste: el.taste,
              comment: el.comment,
              special_marking: el.special_marking,
            };
            req.push(d);
          }
        });

        try {
          this.overlay = true;
          await api.savingGrn(req);
          // this.clear();
          this.showSuccessAlert("Data Saved");
          this.initialize();
          this.overlay = false;
        } catch (error) {
          this.showErrorAlert(error);
        }
      } else {
        this.noDataAlert("Select Atleast One Row...");
      }
    },

    // ## API call from BLEND module
    getItemName(item) {
      if (
        item.body > 0 &&
        item.color > 0 &&
        item.leaf_appearance > 0 &&
        item.leaf_size > 0 &&
        item.taste > 0
      ) {
        blend
          .getItemName(
            item.body,
            item.color,
            item.leaf_appearance,
            item.leaf_size,
            item.taste
          )
          .then((res) => {
            item.item_name_1 = res.data.name;
            item.item_name = this.setItemName(item);
          });
      }
    },

    setItemName(item) {
      if (item.special_marking) {
        return `${item.item_name_1}${item.special_marking}`;
      } else {
        return item.item_name_1;
      }
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
    },
    noDataAlert(message) {
      this.$swal({
        showConfirmButton: false,
        text: message,
        timer: 3000,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    // clear(){
    //   this.
    // }
  },
  created() {
    this.overlay = true;
    this.initialize();
    this.overlay = false;
  },
};
</script>

<style></style>
